<template>
    <div>
        <!-- 1L -->
        <van-sticky>
            <van-nav-bar
                    right-text="历史订单"
                    :left-arrow="false"
                    title="订单详情"
                    @click-right="onClickRight"
            >
                <template #right>
                    <van-icon>
                        <svg class="icon" aria-hidden="true" font-size="30px">
                            <use xlink:href="#imfyc-icon-lishidingdan"></use>
                        </svg>
                    </van-icon>
                </template>
            </van-nav-bar>
        </van-sticky>
        <!-- 1--2 -->
        <van-cell-group>
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                <!-- 2L -->
                <van-empty image="search" description="参数有误，请重试！" v-if="checkIsNull === true"/>
                <!-- 3L -->
                <van-cell-group v-else>
                    <!-- 3.1L -->
                    <van-cell  v-for="item in orderList" :key="item.id">
                        <van-card :title="item.DeviceSN + '号设备-' + item.PortSn + '号充电枪'"
                        >
                            <template #thumb>
                                <van-icon>
                                    <svg class="icon" aria-hidden="true" font-size="150px" style="margin-left:-40px;">
                                        <use xlink:href="#imfyc-icon-chongdianzhuang"></use>
                                    </svg>
                                </van-icon>
                            </template>
                            <template #tags>
                                套餐：￥{{ item.Price }}&nbsp;/&nbsp;{{ item.UnitTime }}分钟
                                <br>
                                开始时间：{{ item.ChargingStartTime}}
                                <br>
                                结束时间：{{ item.ActualEndTime }}
                                <br>
                                <van-tag mark type="danger">
                                    {{ item.TransactionStatesName }}
                                </van-tag>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <van-tag mark type="danger">
                                    {{ item.UsageStatesName }}
                                </van-tag>
                            </template>
                            <template #price>
                                应付：<span>￥{{ item.TransactionPrice }}</span>
                                <br>
                                实付：<span>￥{{ item.ActualPayment }}</span>
                                <br>
                                <span v-if="item.Refund !== null && item.Refund !== ''">
                            退款：￥{{ item.Refund }}
                        </span>
                            </template>
                        </van-card>
                    </van-cell>
                    <!-- 3.2L -->
                    <van-cell
                            :style="{'height':(status===2|| status === 4 || status === 7  || status === 9  || status === 5 || status === 6)?'170px':'100px','text-align': center}">
                        <van-row type="flex" justify="center">
                            <van-col >
                                <span style="font-size: 20px;">剩余充电时长</span>
                            </van-col>
                        </van-row>
                        <van-row type="flex" justify="center" style="margin-top: 20px;">
                            <van-col >
                                <van-count-down ref="countDown" :time="countDownTime"
                                                style="font-size: 40px;font-weight: bold"
                                                @finish="timeFinish"
                                                @change="timeChange"/>
                            </van-col>
                        </van-row>
                        <van-row type="flex" justify="center" style="margin-top: 20px;" v-if="isShow||status === 2 || status === 7">
                            <van-col  style="margin-right: 5px;">
                                <van-button round type="danger" size="normal" @click="sendEnd">
                                    结束充电
                                </van-button>
                            </van-col>
                            <van-col style="margin-left: 5px;">
                                <van-button round type="primary" size="normal" @click="renewal" :disabled="!canClick">续充加时
                                </van-button>
                            </van-col>
                        </van-row>
                        <van-row type="flex" justify="center" style="margin-top: 20px;" v-if="status === 5 || status === 6">
                            <van-col>
                                <van-button round type="warning" size="normal" @click="sendStatr">
                                    再次启动
                                </van-button>
                            </van-col>
                        </van-row>
                    </van-cell>
                    <!-- 3.3L -->
                    <van-sku
                            v-model="showDiscount"
                            :sku="sku"
                            :show-header-image="false"
                    >
                        <template #sku-header>
                            <van-cell title="请选择续费套餐"
                                      style="text-align: center;font-weight: bold;font-size: 17px;"></van-cell>
                            <van-notice-bar left-icon="volume-o" :scrollable="false">
                                <template #default>
                                    您正使用{{ portSn }}号端口充电，续充将延长该端口时长。
                                </template>
                            </van-notice-bar>
                        </template>
                        <template #sku-group :class="'class_list'">
                            <van-list>
                            <van-cell :class="[item.ID==discountIndex?'discount-active':'discount-normal']" center v-for="(item,i) in discounts" :key="i" :value="'￥'+item.Price"
                                      clickable @click="chooseDiscount(item)">
                                <template #icon>
                                    <van-icon>
                                        <svg class="icon" aria-hidden="true" font-size="24px">
                                            <use xlink:href="#imfyc-icon-dianchidianya"></use>
                                        </svg>
                                    </van-icon>
                                </template>
                                <template #title>
                                    {{ item.UnitTime +'分钟'}}
                                </template>
                                <template #label>
                                    {{ item.Name }}
                                </template>
                            </van-cell>
                            </van-list>
                        </template>
                        <template #sku-stepper>
                            <span/>
                        </template>
                        <template #sku-actions>
                            <van-goods-action>
                                <van-goods-action-icon color="#07c160" @click="customerService">
                                    <template>
                                        <van-icon class-prefix="imfyc-icon" name="kefu" size="0.9rem"/>
                                        客服
                                    </template>
                                </van-goods-action-icon>
                                <van-goods-action-button icon="balance-o" type="warning" text="优惠充值" disabled/>
                                <van-goods-action-button icon="gold-coin" type="danger" text="立即支付" @click="pay"/>
                            </van-goods-action>
                        </template>
                    </van-sku>

                    <div class="pull_refresh_bottom_text">
                        如订单显示状态与实际设备状态不符<br>请下拉刷新状态
                    </div>
                </van-cell-group>
            </van-pull-refresh>
        </van-cell-group>
    </div>
</template>

<script>
    import {getCallBackReq1, handleCountDownTime, isEmpty, isNotEmpty} from "../../utils/common";
    import {
        Button,
        Card,
        Cell,
        CellGroup,
        Col,
        CountDown,
        Empty,
        GoodsAction,
        GoodsActionButton,
        GoodsActionIcon,
        Icon,
        List,
        Loading,
        NavBar,
        NoticeBar,
        PullRefresh,
        Row,
        Sku,
        Sticky,
        Tag
    } from 'vant';

    export default {
        name: "item",
        components: {
            [NavBar.name]: NavBar,
            [CountDown.name]: CountDown,
            [Card.name]: Card,
            [Row.name]: Row,
            [Icon.name]: Icon,
            [Button.name]: Button,
            [Loading.name]: Loading,
            [Empty.name]: Empty,
            [List.name]: List,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Col.name]: Col,
            [List.name]: List,
            [Tag.name]: Tag,
            [Sku.name]: Sku,
            [NoticeBar.name]: NoticeBar,
            [GoodsAction.name]: GoodsAction,
            [GoodsActionButton.name]: GoodsActionButton,
            [GoodsActionIcon.name]: GoodsActionIcon,
            [PullRefresh.name]: PullRefresh,
            [Sticky.name]: Sticky
        },
        data() {
            return {
                navBarTitle: '订单详情',
                countDownTime: 0,
                checkIsNull: false,
                orderTitle: '',
                orderDescDiscount: '',
                orderDescTime: '',
                orderPrice: 0,
                orderList: [],
                order: {},
                userSession: {},
                orderSession: {},
                deviceSn: '',
                device: null,
                portSn: '',
                createTime: '',
                status: '',
                transactionStates: '',
                // // 状态：作废（付款成功，但命令尚未下达，经彭峥确认，延迟五秒后再次查询，2020-10-23 15:23:27）
                // queryAgain: false,
                timeCount: 0,
                timeStartOrderCount:0,//开始订单信息获取次数
                timeEndOrderCount:0,//结束订单信息获取次数
                user: null,
                userId: null,
                startTimeFinish: false,
                timeChangeMax: 0,
                showDiscount: false,
                sku: {
                    hide_stock: true
                },
                discounts: [],
                curDiscount: null,
                discountIndex:null,
                codeSource: null,
                isLoading: false,
                isShow:false,//结束充电和续充按钮是否显示
                isFirst:true,//是否是第一次加载
                canClick:true//续充按钮是否可用
            };
        }

        , mounted() {
            // handleCountDownTime('2020-10-29T12:54:30.393');
            // // // this.timeReset(105768);

            this.getCodeSource();
            this.initParams();
            this.initData();
        }

        , methods: {
            //
            onRefresh() {
                this.isLoading = true;
                this.checkIsNull = false;
                this.initData();
            },
            // 初始化参数
            initParams() {
                // 来自微信
                this.orderSession = JSON.parse(sessionStorage.getItem('ORDER'));
                if (isEmpty(this.orderSession)) {
                    // 来自支付宝
                    this.orderSession = getCallBackReq1();
                    let user = {};
                    user.ID = this.orderSession.UserID;
                    // alert('userID:'+this.orderSession.UserID)
                    localStorage.setItem('USER',JSON.stringify(user));
                    // this.codeSource = 2;
                    // } else {
                    //     this.codeSource = 1;
                }
            }
            // 初始化大本营
            , initData() {
                //
                let data = {};
                data.OrderNo = isEmpty(this.orderSession.OrderNo) ? this.orderSession.out_trade_no : this.orderSession.OrderNo;
                if (isEmpty(data.OrderNo)) {
                    this.checkIsNull = true;
                    return;
                }
                // 支付宝，需要先根据out_trade_no（交易单号）查询OrderNo
                if (this.codeSource === 2) {
                    let tmp = {};
                    tmp.MerchantOrderNumber = data.OrderNo;
                    this.getOrderTransactionsForAli(tmp);
                    return;
                }
                // 微信，直接根据OrderNo查询
                this.getOrderInfoAndTransactions(data);
            }
            //
            , timeFinish() {
                if (this.startTimeFinish) {
                    this.initData();
                }
            }
            //
            , timeChange() {
                if(this.isFirst){
                    this.isShow = true;
                    this.isFirst = false;
                }
                //
                if (this.timeChangeMax > 0 || this.status === 2 || this.status === 3) {
                    return;
                }
                //
                this.timeCount++;
                if (this.timeCount === 5) {
                    this.initData();
                    this.timeCount = 0;
                    this.timeChangeMax = 1
                }
            }
            // 订单页
            , onClickRight() {
                this.$router.push({name: 'order', params: {userId: this.userId}});
            }
            // 结束
            , sendEnd() {
                //
                this.canClick = true;
                let obj = this.handleStartAndEndMerchantOrderNumber(2);
                if (isEmpty(obj)) {
                    this.$toast.fail('暂无可关闭设备');
                    return;
                }
                //
                let end = this.handleSendStatrOrSendEnd(obj);
                end.EndState = '01';
                //
                this.$api.api.req(this.$api.order.sendCloseDeviceCommand(end)).then(resp => {
                    //
                    let data = {};
                    data.OrderNo = this.order.OrderNo;
                    this.timeEndOrderCount = 0;
                    this.queryOrderInfo(data,'end');
                })
            }
            //查询订单信息
            , queryOrderInfo(data,type){
                switch (type) {
                    case 'start':
                        this.$api.api.req(this.$api.order.getQueryOrderInfo(data)).then(resp => {
                            //
                            if (isEmpty(resp) || isEmpty(resp.data)) {
                                this.$toast.fail('订单获取失败');
                                return;
                            }
                            //
                            this.handleOrderInfo(resp.data[0]);
                            //
                            if(resp.data[0].States === 2){
                                this.timeStartOrderCount = 5;
                            }
                            setTimeout(() => {
                                if(this.timeStartOrderCount<5){
                                    this.queryOrderInfo(data,'start');
                                    this.timeStartOrderCount++
                                }
                            }, 2000);
                        }).catch(error => {
                            this.$toast.fail('订单获取异常');
                        })
                        //获取交易记录
                        this.getOrderTransactions(data);
                        break;
                    case 'end':
                        this.$api.api.req(this.$api.order.getQueryOrderInfo(data)).then(resp1 => {
                            //
                            this.canClick = false;
                            // alert('resp:'+resp1);
                            if (isEmpty(resp1) || isEmpty(resp1.data)) {
                                this.$toast.fail('请下拉刷新状态');
                                return;
                            }
                            //
                            // setTimeout(() => {
                            //     alert('end:'+this.timeEndOrderCount)
                            //     if(this.timeEndOrderCount<5){
                            //         this.queryOrderInfo(data,'end');
                            //         this.timeEndOrderCount++
                            //     }
                            // }, 2000);
                            if (resp1.data[0].States === 3) {
                                // alert('States:'+this.timeEndOrderCount)
                                //
                                this.handleOrderInfo(resp1.data[0]);
                                //
                                this.getOrderTransactions(data);
                                this.timeEndOrderCount = 5;
                            }else {
                                setTimeout(() => {
                                    // alert('end:'+this.timeEndOrderCount)
                                    if(this.timeEndOrderCount<5){
                                        this.queryOrderInfo(data,'end');
                                        this.timeEndOrderCount++
                                    }
                                }, 2000);
                            }
                        }).catch(error => {
                            this.$toast.fail('订单获取异常');
                        })
                        break;
                }
            }
            // 再次启动
            , sendStatr() {
                //
                let obj = this.handleStartAndEndMerchantOrderNumber(1);
                if (isEmpty(obj)) {
                    // this.$toast.fail('暂无可启动设备');
                    this.initData();
                    return;
                }
                //
                let start = this.handleSendStatrOrSendEnd(obj);
                start.PaymentPackageID = obj.PaymentPackageID;
                //
                this.$api.api.req(this.$api.order.sendStartDeviceCommand(start)).then(resp => {
                    this.timeReset(0);
                    this.initData();
                })
            }
            // 续充
            , renewal() {
                //
                let discount = {};
                //
                discount.DeviceSN = this.deviceSn;
                discount.userID = this.userId;
                //
                this.$api.api.req(this.$api.discount.getDiscountCustomer(discount)).then(resp => {
                    // 当前设备下的优惠套餐
                    this.discounts = resp.data;
                    //
                    this.finished = true
                });
                //
                this.showDiscount = true;
                //
            }
            //
            , getOrderInfoAndTransactions(data) {
                this.timeStartOrderCount = 0;
                //
                this.queryOrderInfo(data,'start');
            }
            //
            , handleOrderInfo(order) {
                //
                this.order = order;
                //
                this.status = this.order.States;
                //
                if (this.status === 2 || this.status === 4 || this.status === 7 || this.status === 9) {
                    this.startTimeFinish = true;
                    this.timeReset(handleCountDownTime(this.order.ChargingEndTime));
                    return;
                }
                if(this.status === 5 || this.status === 6){
                    this.isShow = false;
                }
                this.startTimeFinish = false;
                this.timeReset(0);

            }
            //
            , getOrderTransactions(data) {
                this.$api.api.req(this.$api.order.getOrderTransactions(data)).then(resp1 => {
                    //
                    if (isEmpty(resp1) || isEmpty(resp1.data)) {
                        this.$toast.fail('订单记录获取失败');
                        return;
                    }
                    //
                    this.orderList = resp1.data;
                    //
                    this.deviceSn = this.orderList[0].DeviceSN;
                    this.portSn = this.orderList[0].PortSn;
                    this.userId = this.orderList[0].UserID;
                    //
                    this.isLoading = false;
                }).catch(error => {
                    this.$toast.fail('订单记录获取异常');
                })
            }
            //
            , getOrderTransactionsForAli(data) {
                this.$api.api.req(this.$api.order.getOrderTransactions(data)).then(resp1 => {
                    //
                    if (isEmpty(resp1) || isEmpty(resp1.data)) {
                        this.$toast.fail('支付宝订单记录获取失败');
                        return;
                    }
                    //
                    data = {};
                    data.OrderNo = resp1.data[0].OrderNo;
                    //获取交易记录
                    this.getOrderInfoAndTransactions(data)

                }).catch(error => {
                    this.$toast.fail('支付宝订单记录获取异常');
                })
            }

            // 用于获取启动（取1）、关闭（2）的最后一条交易单号
            , handleStartAndEndMerchantOrderNumber(type) {
                let tmp;
                for (let i = 0; i <= this.orderList.length - 1; i++) {
                    // 获取启动时最后一条数据
                    if (type === 1 && this.orderList[i].UsageStatus === 1) {
                        tmp = this.orderList[i];
                    }
                    // 获取关闭时最后一条数据
                    if (type === 2 && this.orderList[i].UsageStatus === 2) {
                        tmp = this.orderList[i];
                    }
                }
                return tmp;
            }
            //
            , handleSendStatrOrSendEnd(data) {
                let obj = {};
                obj.DeviceSN = data.DeviceSN;
                obj.ChannelNo = data.PortSn;
                obj.MerchantOrderNumber = data.MerchantOrderNumber;
                obj.userID = data.UserID;
                obj.OrderNo = data.OrderNo;
                return obj;
            }
            // 处理套餐选中
            , chooseDiscount: function (item) {
                this.curDiscount = item;
                // 选中
                if (null == this.discountIndex) {
                    this.discountActive(item.ID);
                    //
                    return;
                }
                // 取消
                if (this.discountIndex === item.ID) {
                    this.discountNoActive(item.ID);
                    return;
                }
                // 已有其他，关闭上一个选中的
                this.discountNoActive(this.discountIndex);
                this.curDiscount = item;
                this.discountActive(item.ID);
            }
            // 客服
            , customerService() {
                window.location.href = 'tel:15340996979'
            }
            // 支付
            , pay() {
                this.payForGetUser();
            }
            //
            , payForGetUser() {
                // 获取用户
                let obj = {};
                obj.ID = this.userId;
                this.$api.api.req(this.$api.user.getUser(obj)).then(resp => {
                    //
                    if (isEmpty(resp) || isEmpty(resp.data)) {
                        this.$toast.fail('用户异常，请稍后再试');
                        return;
                    }
                    //
                    this.user = resp.data[0];
                    //
                    this.payForGetDevice();
                });
            }
            //
            , payForGetDevice() {
                let device = {};
                device.DeviceSN = this.deviceSn;
                this.$api.api.req(this.$api.device.getDeviceBySn(device)).then(resp => {
                    //
                    if (isEmpty(resp.data) || resp.data.length === 0 || isEmpty(resp.data[0].IPAddress)) {
                        this.deviceIsNull = true;
                        this.$toast.fail('设备异常，请稍后再试');
                        return;
                    }
                    //
                    this.device = resp.data[0];
                    //
                    this.payCheckPortSnAndcurDiscount();
                })
            }
            //
            , payCheckPortSnAndcurDiscount() {
                if (isEmpty(this.portSn)) {
                    this.$toast.fail("端口获取失败");
                    return;
                }
                if (isEmpty(this.curDiscount)) {
                    this.$toast.fail("请选择套餐");
                    return;
                }
                //
                this.payForSource();
            }
            //
            , payForSource() {
                //
                let data = this.$api.pay.initPayParams(this.user, this.device, this.portSn, this.curDiscount);
                data.OrderNo = this.order.OrderNo;
                //
                if (this.codeSource === 1 && isNotEmpty(this.user.WXOpenid)) {
                    data.OrderType = 2;
                    data.PaymentType = 1; // 微信1，小程序2，支付宝3
                    data.Openid = this.user.WXOpenid;
                    data.TransactionPrice = data.TransactionPrice * 100;
                    this.$api.api.req(this.$api.pay.weiXinRenewal(data)).then(resp => {
                        //
                        if (isEmpty(resp.data)) {
                            this.$toast.fail('支付失败，请重试');
                            return;
                        }
                        //
                        sessionStorage.setItem('ORDER', JSON.stringify(resp.data.OrderTransactionsmodel));
                        //
                        // this.$api.pay.wxPay(resp.data.wxJsApiParam, this);
                        this.wxPay(resp.data.wxJsApiParam);
                        this.curDiscount = null;
                    }).catch(resp => {
                        this.$toast.fail('支付失败，请重试');
                    });
                    return;
                }
                //
                if (this.codeSource === 2 && isNotEmpty(this.user.ZFBOpenid)) {
                    data.OrderType = 2;
                    data.PaymentType = 3; // 微信1，小程序2，支付宝3
                    data.Openid = this.user.ZFBOpenid;
                    this.$api.api.req(this.$api.pay.aLiRenewal(data)).then(resp => {
                        //
                        if (isEmpty(resp.data)) {
                            this.$toast.fail('支付失败，请重试');
                            return;
                        }
                        //
                        const div = document.createElement('div')
                        div.innerHTML = resp.data.zfbJsApiParam;
                        document.body.appendChild(div);
                        document.forms[0].submit();
                    }).catch(resp => {
                        this.$toast.fail('支付失败，请重试');
                    });
                    return;
                }
                //
                this.$toast.fail('支付方式错误，请重试');
            }
            // 端口选中
            , portActive(index, portId) {
                //
                this.portId = portId;
                this.portIndex = index;
                //
                let that = this.$refs['ports' + this.portIndex];
                that[0].$el.firstChild.style.border = "1px solid #1989fa"
                that[0].$el.firstChild.firstChild.style.color = "#1989fa";
            }
            // 端口取消选中
            , portNoActive(index) {
                //
                this.portIndex = null;
                this.portId = null;
                this.curPort = null;
                //
                let that = this.$refs['ports' + index];
                that[0].$el.firstChild.style.border = ""
                that[0].$el.firstChild.firstChild.style.color = "";
            }
            // 套餐选中
            , discountActive(index) {
                this.discountIndex = index;
                // this.$refs['discount' + this.discountIndex].style.border = "1px solid #1989fa";
            }
            // 套餐取消选中
            , discountNoActive(index) {
                this.discountIndex = null;
                this.curDiscount = null;
                // this.$refs['discount' + index].style.border = "";
            }
            /////////////////////////////////////////////////////
            , wxPay(data) {
                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', this.wxJsApiCall(data), false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', this.wxJsApiCall(data));
                        document.attachEvent('onWeixinJSBridgeReady', this.wxJsApiCall(data));
                    }
                } else {
                    this.wxJsApiCall(data);
                }
            }

            , wxJsApiCall(data) {
                let tmp = JSON.parse(data);
                const that = this;
                WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', {
                        "appId": tmp.appId,     //公众号名称，由商户传入
                        "timeStamp": tmp.timeStamp,         //时间戳，自1970年以来的秒数
                        "nonceStr": tmp.nonceStr,  //随机串
                        "package": tmp.package,
                        "signType": tmp.signType,         //微信签名方式：
                        "paySign": tmp.paySign //微信签名
                    },
                    function (res) {
                        //
                        if (res.err_msg === 'get_brand_wcpay_request:cancel') {
                            return;
                        }
                        //
                        if (res.err_msg === 'get_brand_wcpay_request:ok') {
                            that.showDiscount = false;
                            window.location.reload()
                            return;
                        }
                        //
                        this.$toast.fail('下单失败');
                    });
            }
            //
            , timeReset(time) {
                this.countDownTime = time;
                if (isEmpty(this.$refs.countDown)) {
                    return;
                }
                this.$refs.countDown.reset();
            }

            // 获取扫码来源
            ,
            getCodeSource() {
                if (this.$client.WEIXIN) {
                    this.codeSource = 1;
                } else if (this.$client.ALIPAY) {
                    this.codeSource = 2;
                }
            }

        }
    }
</script>

<style scoped>
    .discount-active {
        border: 1px solid #1989fa;
    }
    .discount-normal {
        border: 0;
    }
    .class_list{
        padding: 2px;
    }
</style>
